/* Listing PROFILE */

.SectionCellGroup {
	max-width: 790px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 20px;

	@media (max-width: 767px) {
		grid-template-columns: repeat(1, 1fr);
	}
}