#card {
	.CardAnim {
		width: 300px;
		height: 225px;
		position: relative;
		overflow: hidden;
		border-radius: 20px;
		display: grid;
		grid-template-rows: 1fr 1fr;
		transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0);
	}

	@media (max-width: 330px) {
		.CardAnim {
			width: 280px;
			height: 205px;
		}
	}

	.CardAnim:hover {
		transform: scale(1.1, 1.1);
		box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
	}

	.overlay {
		position: absolute;
		border-radius: 20px;
		z-index: 30;
		height: 100%;
		width: 100%;
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.3);
		transition: 0.8s;
	}

	.overlay:hover {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0);
	}

	.CardAnim img {
		height: 110%;
		/* Bose on the Card that have a relative position */
		position: absolute;
		top: 0;
		z-index: 3;
		transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
		background-color: rgba(0, 0, 0, 0);
	}

	.CardAnim:hover img {
		transform: translateY(-17px);
	}
}

#CardGroup {
	max-width: 660px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	/* Centered the card */
	justify-items: center;

	@media (max-width: 991px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 770px) {
		grid-template-columns: repeat(1, 1fr);
	}
}