.PlayGroup {
	position: relative;
	width: 100%;
}

.PlayButton {
	/* centred / in front */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	/* Styled button */
	padding: 35px;
	border-radius: 80px;
	border: none;
	outline: none;
	background-image: linear-gradient(to right, rgba(198, 223, 233, 0.6), rgba(10, 90, 255, 0.7));

	transition: 1.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.PlayButton:hover {
	background-color: rgba(18, 93, 255, 0.6);
	cursor: pointer;
}

.IconPlay {
	height: 50px;
}

@media (max-width: 500px) {
	.PlayButton {
		padding: 20px;
	}

	.IconPlay {
		height: 30px;
	}
}

.modal-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1000000;
	cursor: pointer;
	animation: VideoAnimation 4s 0.2s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
	opacity: 0;
}

/* Apparition */
@keyframes VideoAnimation {
	0% {
		opacity: 0;
		transform: translateY(25px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

/* Disparition */
.modal-video-effect-exit {
	opacity: 0;
}

.modal-video-movie-wrap {
	transform: translateY(100px);
}

/* Center video */
.modal-video-body {
	max-width: 1200px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: table;
}

/* Center video */
.modal-video-inner {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

/* Center video */
.modal-video-movie-wrap {
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	background-color: #333;
	animation: VideoAnimation 3s 0.2s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

/*video position / size / respinsive on My container*/
iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* The closing Button "X" */
.modal-video-close-btn {
	position: absolute;
	z-index: 2;
	top: -50px;
	right: 0px;
	display: table-cell;
	vertical-align: middle;
	width: 40px;
	height: 40px;
	overflow: hidden;
	border: none;
	border-radius: 8px;
	background: transparent;
}

.modal-video-close-btn:hover {
	background: transparent;
	transform: translateY(-3px);
}

.modal-video-close-btn:before {
	transform: rotate(45deg);
}

.modal-video-close-btn:after {
	transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
	content: '';
	position: absolute;
	height: 4px;
	width: 100%;
	top: 50%;
	left: 0;
	background: #ffffff;
	border-radius: 8px;
}