.columnImage{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 0;
	justify-items: center;

	@media (max-width: 991px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.SvgImage {
  	@media (max-width: 991px) {
      width: 200px;	
    }
}

.MyImage {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.shadowImage {
	box-shadow: 0 30px 60px rgba(92, 92, 92, 0.1);
}

#beforeAfter {
  position:relative;
  height: 600px;
  margin: 0 auto;

  img {
    position: absolute;
    left: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }

    @media (max-width: 1200px) {
      height: 500px;	
    }
    @media (max-width: 985px) {
      height: 360px;	
    }
    @media (max-width: 765px) {
      height: 260px;	
    }
    @media (max-width: 500px) {
      height: 190px;	
    }
    @media (max-width: 420px) {
      height: 150px;	
    }
    @media (max-width: 400px) {
      height: 130px;	
    }
    @media (max-width: 350px) {
      height: 110px;	
    }
}

#beforeAfter img.top:hover {
  opacity: 0;
  cursor: pointer;
}