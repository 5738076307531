#Footer {
	.ActiveHoverInfo {
		.OpacityInfo {
			text-align: center;
			justify-content: center;
			top: -50px;
			left: 50%;
			opacity: 0;
			position: absolute;
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			transition: 0.8s;
			-webkit-transition: 0.8s;
			-moz-transition: 0.8s;
			-ms-transition: 0.8s;
			-o-transition: 0.8s;
		}

		:hover,
		:active {
			.OpacityInfo {
				opacity: 1;
			}
		}
	}
}