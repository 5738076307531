#imageHeader {
	height: 100vh;
	background-image: url("../images/Projects/Deltu/deltu.jpg");
  	background-attachment: fixed;
  	background-position: center;
  	background-repeat: no-repeat;
  	background-size: cover;

	/* Turn off parallax scrolling for all tablets and phones */
	@media (max-width: 1366px) {
		background-attachment: scroll;
	}
}

.mainHeaderText {
	text-align: left;
	width: 100%;
	background-color: rgba(0,0,0,1);
}