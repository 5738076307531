#service {
    .container-fluid {
        max-width: 790px;
    }

    h4 {
        line-height: 1.5rem;
    }

    @media (max-width: 400px) {
        .col {
            max-width: 140px;
            min-width: 140px;
        }

        h4 {
            font-size: 0.7rem;
        }
    }

}