/* - - - - - - - Nav - - - - - - - - */
.navi {
	background-color: black;
}

.NavBar {
	max-width: 2600px;
	width: 100%;
	max-height: 40px;
}

/* - - - - - - - Navigation - - - - - - - - */
.BtnHoverBug:hover div {
	color: black;
}

.FontSize {
	font-size: 1.1rem;
}

.RoundBtn {
	height: 60px;
	width: 60px;
}