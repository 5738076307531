@font-face {
	font-family: 'vsDecimalGroteskReg';
	src: url('./fonts/vsDecimalGrotesk/VSDecimalGrotesk-Regular.otf');
	src: local('vsDecimalGroteskReg'), local('vsDecimalGroteskReg'),
		url('./fonts/vsDecimalGrotesk/VSDecimalGrotesk-Regular.otf') format('opentype');
}

html {
	width: 100%;
}

body {
	background-color: rgb(250, 250, 250);
	margin: 0;
	padding: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif; */
	font-family: 'vsDecimalGroteskReg', opentype;
	color: white;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

/* - - - - - - - CENTER - - - - - - - - */
.CenterDiv {
	text-align: center;
	top: 50%;
	left: 50%;
	position: absolute;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

/* - - - - - - - BUTTON - - - - - - - - */
/* WHITE */
.BtnWhite {
	padding: 10px 20px 7px 20px;
	background: rgba(255, 255, 255, 0);
	border-radius: 20px;
	border: 2px solid rgba(255, 255, 255, 1);
	color: white;
	outline: none;
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.BtnWhite:hover {
	background: rgb(255, 255, 255);
	border: 2px solid rgba(255, 255, 255, 1);
	color: black;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.active .BtnWhite {
	background: rgb(255, 255, 255);
	border: 2px solid rgba(255, 255, 255, 1);
	color: black;
}

/* BLACK */
.BtnBlack {
	padding: 10px 20px 7px 20px;
	background: rgba(255, 255, 255, 0);
	border-radius: 20px;
	border: 2px solid rgba(0, 0, 0, 1);
	color: black;
	outline: none;
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	text-decoration: none;
}

.BtnBlack:hover {
	background: rgb(0, 0, 0);
	border: 2px solid rgba(0, 0, 0, 1);
	color: white;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	text-decoration: none;
}

.active .BtnBlack {
	background: rgb(0, 0, 0);
	border: 2px solid rgba(0, 0, 0, 1);
	color: white;
}

.btn {
	border-radius: 6rem;
	outline: none;
}

.BlackHover {
	color: white;
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	text-decoration: none;
	-webkit-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	-moz-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	-ms-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	-o-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.BlackHover:hover {
	color: black;
}

/* - - - - - - - TYPO LineHeight - - - - - - - - */
.NolineHeight {
	line-height: 0;
}

.LineHeight {
	line-height: 2.3rem;
}

/* - - - - - - - For my Black Bottom Border - - - - - - - - */
.border-3 {
	border-width: 20px !important;
}

/* - - - - - - - For all my fullImage - - - - - - - - */
.FullImage {
	width: 100%;
}

/* - - - - - - - Animation - - - - - - - - */
.HoverAnimation {
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.HoverAnimation:hover {
	transform: scale(1.1, 1.1);
}

/* - - - - - - - Col - - - - - - - - */
.MinWidth {
	min-width: 270px;
}

.MinWidthSmall {
	min-width: 200px;
}

.MaxWidth {
	max-width: 2000px;
}

/* - - - - - - - SPACER - - - - - - - - */
.Mt-Negatif {
	margin-top: -115px;
}

/* - - - - - - - TYPO SIZE - - - - - - - - */
/*
*   h1 Header
*   h2 Sous-titre
*   h3 Buttons + footer + &copy;
*   h4 Paragraphe
*   h5 Quotes
*/
h4 {
	line-height: 2.2rem;
}

h3 {
	line-height: 1rem;
}

h2 {
	line-height: 3rem;
}

h1 {
	line-height: 3.3rem;
}

h5 {
	line-height: 3.3rem;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

/* - - - - - - - - - - - - - - - - - - - -- - - - MEDIA QUERY - - - - -- - - - -- - - -- - -  -- - -- - - --  - - - */
/** Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
	h4 {
		font-size: 0.7rem;
		line-height: 1.8rem;
	}
}

/** Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
	h5 {
		font-size: 1.3rem;
		line-height: 2.3rem;
	}

	h1 {
		font-size: 1.3rem;
		line-height: 2.3rem;
	}
}

/** Extra small devices (portrait phones, less than 414px) */
@media (max-width: 340px) {
	h1 {
		font-size: 1.1rem;
		line-height: 2.1rem;
	}
}

.slider{
	width: 540px; /*Same as width of the large image*/
	position: relative;
	/*Instead of height we will use padding*/
	padding-top: 320px; /*That helps bring the labels down*/
	
	margin: 200px auto;
	
	/*Lets add a shadow*/
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.75);
}


/*Last thing remaining is to add transitions*/
.slider>img{
	position: absolute;
	left: 0; top: 0;
	transition: all 0.5s;
}

.slider input[name='slide_switch'] {
	display: none;
}

.slider label {
	/*Lets add some spacing for the thumbnails*/
	margin: 18px 0 0 18px;
	border: 3px solid #999;
	
	float: left;
	cursor: pointer;
	transition: all 0.5s;
	
	/*Default style = low opacity*/
	opacity: 0.6;
}

.slider label img{
	display: block;
}

/*Time to add the click effects*/
.slider input[name='slide_switch']:checked+label {
	border-color: #666;
	opacity: 1;
}
/*Clicking any thumbnail now should change its opacity(style)*/
/*Time to work on the main images*/
.slider input[name='slide_switch'] ~ img {
	opacity: 0;
}
/*That hides all main images at a 110% size
On click the images will be displayed at normal size to complete the effect
*/
.slider input[name='slide_switch']:checked+label+img {
	opacity: 1;
}
/*Clicking on any thumbnail now should activate the image related to it*/

/*We are done :)*/