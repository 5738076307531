@import './font.scss';
@import './footer.scss';
@import './card.scss';
@import './header.scss';
@import './image.scss';
@import './video.scss';
@import './navbar.scss';
@import './skills.scss';
@import './service.scss';

// Border radius
$border-radius: 1.3rem;

// mx- my- mt- mb- !!!
$spacers: ('6': 5rem, '8': 6.5rem, '11': 9rem);

// bg et text
$dark: black;
$primary: #c4a884; // Title's colors
$secondary: #d5d5d5 !default;

// Import Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap.scss';